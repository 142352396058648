.flex1 {
  flex: 1;
}
.flexWrapper {
  display: flex;
}
.justifyContentSpaceBetween {
  justify-content: space-between;
}

.modalWrapper {
  height: 500px;
  border: 2px solid indigo;
}
.mapWrapper {
  height: 400px;
  width: 100%;
  margin-bottom: 20px;
}
.agentsInputWrapper {
  margin: 20px 0;
  position: relative;
}
.requiredFieldRedColor {
  color: red;
}

.agentSectionContainer {
  padding: 0 50px;
}
.agentDetailsFormWrapper {
  min-height: 105px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0;
}
.agentListFormWrapper {
  min-height: 105px;
  max-height: 400px;
  padding: 0;
}
.textButtonWrapper {
  display: flex;
  align-items: center;
}
.actionButton {
  color: #00946e;
  font-size: 16px;
}
.deliveryAgentTableWrapper {
  margin-bottom: 25px;
}
.deliveryPartnersRow {
  padding: 10px 0px;
}
.deliveryIcon {
  width: 25px;
  margin-right: 10px;
}
.colorGrey {
  color: grey;
}
.storePropertyTable {
  margin-top: 20px;
}
.backButton {
  cursor: pointer;
  color: #2e5ce6;
  margin-bottom: 10px;
}

.storeDeliveryNote {
  margin-bottom: 15px;
  font-weight: bold;
}

.deliveryAgentTableWrapper .ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8 !important;
  transition: all 0.3s, border 0s;
  padding: 0;
}

.deliveryAgentTableWrapper .ant-table-thead > tr > th {
  background: none !important;
  padding: 16px 0 !important;
}

.editBtnDeliveryAgent {
  color: #00946e !important;
  font-weight: bold;
  padding: 7px;
}

.deleteBtnDeliveryAgent {
  color: #313331 !important;
  font-weight: bold;
  padding: 7px;
}

.storeTimingsWrapper {
  padding-bottom: 15px;
}

.dayswrapper {
  margin: 10px 0;
}

.daysBtn {
  background-color: #00946e !important;
  color: #ffffff !important;
  min-width: 27px;
  height: 27px;
  margin: 0 3px !important;
}

.dayswrapper .ant-btn > span,
.daytimingslotswrapper .ant-btn > span {
  position: absolute;
  top: -4px;
  left: 0px;
  bottom: 0;
  right: 0;
}

.daysInActiveBtn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
  min-width: 27px;
  height: 27px;
  margin: 0 3px !important;
}

.daytimingswrapper {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 6px;
  margin: 0;
  min-height: 170px;
  height: 170px;
  overflow-y: scroll;
}

.daytimingslotswrapper {
  width: 14.2%;
}

.addSlotBtn,
.addSlotBtn .ant-btn:not([disabled]):active,
.addSlotBtn .ant-btn:not([disabled]):hover {
  width: 100%;
  background: #00946e52 0% 0% no-repeat padding-box !important;
  border: 1px solid #00946e !important;
  border-radius: 3px;
  opacity: 1;
  color: #00946e !important;
}

.add-slot-modal-styles .ant-time-picker-large .ant-time-picker-input {
  height: 36px;
  width: 165px;
  font-size: 13px;
}

.deleteSlotBtn,
.deleteSlotBtn .ant-btn:not([disabled]):active,
.deleteSlotBtn .ant-btn:not([disabled]):hover {
  width: 126px;
  height: 37px;
  background: #f37878 0% 0% no-repeat padding-box !important;
  border-radius: 4px;
  opacity: 1;
  color: #fafafa !important;
}

.showSlotTimeBtn {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #9f9f9f;
  opacity: 1;
  margin: 5px 0;
  text-align: center;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
}

.showSlotTimeBtn:hover {
  background-color: #00946e52;
  border-color: #00946e;
  color: #00946e;
}

.store-location-btn {
  border: 0;
  width: 100%;
  text-align: left;
  color: #1a8d5f;
  font-weight: bold;
  font-size: 13px;
  height: 35px;
}

.store-loc-change-btn {
  border: 0;
  width: 25%;
  text-align: left;
  color: #1a8d5f;
  font-weight: bold;
  font-size: 13px;
}

.store-tooltip {
  margin-left: 10px;
}

.store-tooltip-icon {
  margin-right: 0.25rem !important;
  color: gray;
}

.ant-tooltip-inner {
  font-size: 12px;
}

.storeInputLabel {
  padding-top: 12px;
}

.delivery-fleet-wrapper {
  border-top: 2px solid lightgray;
  border-bottom: 2px solid lightgray;
  padding: 20px 16px;
}
.delivery-fleet {
  border-bottom: 2px solid lightgray;
  padding: 20px 16px;
}

.fleet-msg-text {
  color: #00946e;
  font-weight: bold;
  font-size: 12px;
  padding-top: 21px;
}

.fleet-msg-icon {
  padding-right: 6px;
  color: #00946e;
}

/* #locationMap .gm-fullscreen-control {
  top: 25px !important;
}
 */

.store-input-label {
  margin-bottom: 5px;
}

.location-btn {
  display: flex;
  width: 100%;
}

.location-btn.ant-btn > span:first-of-type {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.store-location-modal .ant-modal-close {
  position: absolute;
  left: 0;
}

.store-location-modal .ant-modal-close-x {
  background-color: #fff;
  margin-top: 15px;
  margin-left: 15px;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.store-location-modal .ant-modal-body {
  padding: 6px !important;
}

.agentListFormWrapper [type='number']::-webkit-inner-spin-button,
.agentListFormWrapper [type='number']::-webkit-outer-spin-button,
.store-phone-input [type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.agentListFormWrapper [type='number'],
.store-phone-input [type='number'] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
}

.fleet-check-text {
  padding-top: 11px;
  padding-left: 16px;
  width: 28%;
}

.fleet-msg-wrapper {
  padding-left: 30px;
  padding-top: 10px;
}

@media only screen and (max-width: 1412px) {
  .fleet-msg-wrapper {
    width: 50%;
    padding-top: 0;
  }
}

.create-manager-wrapper input::-webkit-outer-spin-button,
.create-manager-wrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.create-manager-wrapper input[type='number'] {
  -moz-appearance: textfield;
}

.pincode-warning-text {
  margin-left: 20px;
  width: 80%;
  font-size: 0.8rem;
}
.pincode-input-box {
  margin: 0 20px;
  width: 90%;
}
.delivery-radius-container {
  width: 90%;
  margin: 0 20px 0 0;
}
.delivery-radius-bar {
  width: 100%;
  margin-left: 5px;
}
.dot-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: -25px;
}
.radius-value {
  border: 2px solid;
  width: 45px;
  height: 33px;
  border: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -12px;
}
.ant-slider-rail {
  background: #e6e6e6;
}

.ant-slider-handle:focus {
  box-shadow: 0 0 0 5px rgba(26, 141, 95, 0.2);
}

.ant-slider-handle {
  border: 2px solid #1a8d5f !important;
}

.ant-slider-track {
  background-color: #d1e8df !important;
}
.delivery-discount-modal .ant-modal-header {
  padding-bottom: 0px;
}
.main {
  background-color: white;
}
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  border-width: 2px;
}
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab {
  text-align: unset;
}
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1a8d5f;
  border-color: #1a8d5f;
}
.ant-tabs-ink-bar {
  background-color: #1a8d5f;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #1a8d5f;
}
