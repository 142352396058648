.container {
  display: flex;
}

.sidebar {
  flex: 0 0 300px;
  margin-top: 20px;
  position: fixed;
  right: 0;
}
.sidebar-title {
  font-size: 14px;
  font-weight: 600;
  color: #999999;
  line-height: 16.94px;
  margin: 0 0 20px 20px;
}

.content {
  flex: 1;
  margin: 20px;
  background-color: #fff;
  padding: 20px;
}

.category {
  margin-bottom: 20px;
  border: 1px solid transparent;
  padding: 0px;
  /* height: 500px; */
}

.category.active {
  color: red;
}

.answer {
  border-bottom: 1px solid #ccc !important;
}
.section-title {
  font-weight: bold;
  color: #1a8d5f;
}
.search-input {
  margin: 0 30px;
}
.question-container {
  display: flex;
  width: 80%;
}
.search-input {
  width: 30%;
}
.main-container {
  background-color: #fafafa;
  height: 100vh;
  overflow-y: scroll;
}
